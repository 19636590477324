import type { NextRouter } from 'next/router';
import Router from 'next/router';
import { useMount } from 'react-use';

// TODO: Explore if we can redirect this on SSR as well

type Props = {
	to: Parameters<NextRouter['push']>[0];
	replace?: boolean;
};

const SVRedirect = ({ to, replace }: Props) => {
	useMount(() => {
		Router[replace ? 'replace' : 'push'](to);
	});

	return null;
};

export default SVRedirect;
