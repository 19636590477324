import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SelectSiteTemplateMutationMutation,
	type SelectSiteTemplateMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import SiteFragment from './fragments/SiteFragment';

const SelectSiteTemplateMutation: TypedDocumentNode<
	SelectSiteTemplateMutationMutation,
	SelectSiteTemplateMutationMutationVariables
> = gql`
	${SiteFragment}
	mutation SelectSiteTemplateMutation($input: SelectSiteTemplateInput!) {
		selectSiteTemplate(input: $input) {
			...SiteFragment
		}
	}
`;

export default SelectSiteTemplateMutation;
