
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/site-maker/create",
      function () {
        return require("private-next-pages/site-maker/create.page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/site-maker/create"])
      });
    }
  