import SVImage from '@pkgs/shared-client/components/SVImage';
import CoverThumbnailPNG from '@pkgs/shared-client/img/site-maker/template-cover-thumbnail.png';
import GridThumbnailPNG from '@pkgs/shared-client/img/site-maker/template-grid-thumbnail.png';
import HorizontalGridThumbnailPNG from '@pkgs/shared-client/img/site-maker/template-horizontal-grid-thumbnail.png';
import JustTextThumbnailPNG from '@pkgs/shared-client/img/site-maker/template-just_text-thumbnail.png';
import OnePageThumbnailPNG from '@pkgs/shared-client/img/site-maker/template-one_page-thumbnail.png';
import SiteTemplate from '@pkgs/shared/enums/SiteTemplate';

const THUMBNAILS = {
	[SiteTemplate.GRID]: GridThumbnailPNG,
	[SiteTemplate.HORIZONTAL_GRID]: HorizontalGridThumbnailPNG,
	[SiteTemplate.ONE_PAGE]: OnePageThumbnailPNG,
	[SiteTemplate.JUST_TEXT]: JustTextThumbnailPNG,
	[SiteTemplate.COVER]: CoverThumbnailPNG,
};

type Props = {
	templateKey: ValueOf<typeof SiteTemplate>;
	className?: string;
};

const SVSiteTemplateThumbnail = ({ templateKey, className }: Props) => {
	const image = THUMBNAILS[templateKey];

	if (!image) {
		return null;
	}

	return <SVImage alt={templateKey} src={image} className={className} />;
};

export default SVSiteTemplateThumbnail;
